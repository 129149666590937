import React, { useState, useEffect, useRef } from 'react';
import FloatingLabelInput from "../../../common/components/FloatingLabelInput";
import FormSelect from "../../../common/components/FormSelect";
import DateRangeField from "../../../common/components/DateRangeField";
import AsyncMultiSelect from '../../../common/components/AsyncMultiSelect';

const SearchFilters = ({
  testGroup,
  params,
  locationOptions,
  laneOptions,
  consentedToFilterOptions,
  selectedTags,
  tagOptionsUrl,
  appointmentsStatusOptions,
 }) => {
  const [form, setForm] = useState({
    include_archived: "false",
    q: params.q,
    appointment_slot_group_id: params.appointment_slot_group_id,
    test_lane: params.test_lane,
    tested: params.tested,
    consented_to: params.consented_to,
    tags: params.tags,
    archived_status: params.archived_status,
    start_day: params.start_day,
    end_day: params.end_day,
  });
  const initialForm = useRef(form);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchInput, setSearchInput] = useState(params.q);

  const filterParticipants = () => {
    const query = {};
    Object.keys(form).forEach(key => {
      if (form[key]) {
        query[key] = form[key];
      }
    });
    const url = `/test_groups/${testGroup.slug}/participants?` + new URLSearchParams(query).toString();
    window.location.href = url
  }

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  const updateTags = (tags) => {
    let formTags = tags.map(tag => tag.value).join(",");
    setForm({...form, tags: formTags});
  }

  useEffect(() => {
    if (initialForm.current !== form) filterParticipants();
  }, [form]);

  return (
    <div class="d-sm-block d-md-flex align-items-center flex-wrap">
      <FloatingLabelInput
        label="Search by name, email, or phone"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && setForm({...form, q: e.target.value})}
        ariaLabel="Search by name, email, or phone"
        className="d-sm-block flex-md-fill mb-3 mb-md-0"
        style={{minWidth: "305px"}}
        prependIcon="search"
      />
      <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
        <FormSelect
          fieldName="appointment_slot_group_id"
          prompt="Location"
          options={locationOptions}
          selected={form.appointment_slot_group_id}
          onChange={(id) => setForm({...form, appointment_slot_group_id: id})}
        />
      </div>
      <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
        <DateRangeField
          values={[form.start_day, form.end_day]}
          start_day_field_name= "start_day"
          end_day_field_name= "end_day"
          placeholder= "Date range"
          date_format= "MM/DD"
          onChange={(start, end) => setForm({...form, start_day: start.format("YYYY-MM-DD"), end_day: end.format("YYYY-MM-DD")})}
          clearDate={() => setForm({...form, start_day: "", end_day: ""})}
        />
      </div>
      <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
        <FormSelect
          fieldName= "tested"
          prompt= "Status"
          options= {[{label: "Administered", value: "true"}, {label: "Not Administered", value: "false"}]}
          selected= {form.tested}
          onChange={(value) => setForm({...form, tested: value})}
        />
      </div>
      <a className="text-primary inter semibold mx-1 mx-md-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdvanced" aria-expanded="false" aria-controls="collapseAdvanced" onClick={toggleIsExpanded}>
        <i className="fa-regular fa-bars-filter"></i>
      </a>
      <div className="mt-3 collapse" id="collapseAdvanced">
        <div>
          <div className="d-sm-block d-md-inline-block mb-3 mb-md-0">
            <FormSelect
              fieldName= "test_lane"
              prompt= "Lane"
              options={laneOptions}
              selected={form.test_lane}
              onChange={(value) => setForm({...form, test_lane: value})}
            />
          </div>
          <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
            <FormSelect
              fieldName= "consented_to"
              prompt= "Consent"
              options={consentedToFilterOptions}
              selected={form.consented_to}
              onChange={(value) => setForm({...form, consented_to: value})}
            />
          </div>
          <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
            <AsyncMultiSelect
              name= "Tags"
              selected={selectedTags || []}
              optionsUrl={tagOptionsUrl}
              onSelect={(value) => updateTags(value)}
            />
          </div>
          <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
            <FormSelect
              fieldName= "archived_status"
              prompt= "Appointment status"
              options={appointmentsStatusOptions}
              selected={form.archived_status}
              onChange={(value) => setForm({...form, archived_status: value})}
            />
          </div>
        </div>
      </div>
    </div>
  )
 };
export default SearchFilters;
