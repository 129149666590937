import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import ParticipantRow from './ParticipantRow';
import CheckoutButtons from './participants/CheckoutButtons';
import CheckInModal from './participants/CheckInModal';

const ParticipantsTable = (props) => {
  const [appointment, setAppointment] = useState(null);
  const [showCheckInModal, setShowCheckInModal] = useState(false);
  const onCheckIn = (appointment) => {
    setAppointment(appointment);
    setShowCheckInModal(true);
  };

  return (
    <React.Fragment>
      <div className="fw-bold mt-4">{props.total_appointments} results</div>
      <section className="fabrx-tables-light mt-4">
        <Table responsive className="table-lg">
          <thead className="text-gray-dark-700" style={{ backgroundColor: "#FBFBFB" }}>
            <tr>
              <th className="px-3 py-2">
                <span>Time</span>
              </th>
              <th className="px-3 py-2">
                <span>Patient</span>
              </th>
              <th className="px-3 py-2">
                <span>Contact</span>
              </th>
              <th className="px-3 py-2">
                <span>Location</span>
              </th>
              <th className="px-3 py-2">
                <span>Status</span>
              </th>
              <th className="px-3 py-2"></th>
              <th className="px-3 py-2"></th>
            </tr>
          </thead>
          <tbody>
            {props.appointments.map((appointment) => {
              const notCheckoutEligible =
                !appointment.user.is_checkout_eligible &&
                !appointment.user.primary_user_id;
              return (
                <tr
                  key={appointment.id}
                  className="participant__page"
                  data-test-hook={`appointment-${appointment.id}`}
                >
                  <ParticipantRow
                    appointment={appointment}
                    key={appointment.id}
                  />
                  {!['archived', 'draft'].includes(
                      appointment.test_group.status,
                    ) && (
                      <CheckoutButtons
                        appointment={appointment}
                        links={appointment.links}
                        notCheckoutEligible={notCheckoutEligible}
                        onCheckIn={onCheckIn}
                      />
                    )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>
      {appointment && showCheckInModal &&
        <CheckInModal
          setShow={setShowCheckInModal}
          show={showCheckInModal}
          appointment={appointment}
          user={appointment.user}
          testConfigurations={appointment.test_configurations || []}
          symptoms={props.covid_symptoms}
          testGroup={appointment.test_group}
        />
      }
    </React.Fragment>
  );
};

export default ParticipantsTable;
