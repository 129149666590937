import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import StatusBadge from '../../common/components/StatusBadge';

const ParticipantRow = ({ appointment }) => {
  const {
    appointment_slot,
    appointment_slot_group,
    user,
    links,
    global_search,
  } = appointment;

  const notCheckoutEligible =
    !user.is_checkout_eligible && !user.primary_user_id;

  var householdButtonLink = appointment.is_global_search
    ? global_search.global_search_participant
    : links.primary_user_id;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('include_archived') === 'true') {
    const householdButtonLinkParams = new URLSearchParams(
      householdButtonLink.split('?')[1],
    );
    householdButtonLinkParams.set('include_archived', true);
    householdButtonLink =
      householdButtonLink.split('?')[0] + '?' + householdButtonLinkParams;
  }

  const archivedStatus = appointment.archived_status;
  const renderTooltip = (title, props) => (
    <Tooltip className="tooltip-small tooltip-arrow" {...props}>
      {title}
    </Tooltip>
  );

  const IconBadgeWithTooltip = ({
    backgroundColor,
    color,
    icon,
    title,
  }) => {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(title)}
      >
        <span
          className="my-auto me-1"
          style={{
            color: color,
            backgroundColor: backgroundColor,
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          <i className={`fa-regular ${icon}`} style={{height: "12px", marginBottom: "2px"}}></i>
        </span>
      </OverlayTrigger>
    );
  };

  return (
    <React.Fragment>
      <td>
        {!appointment_slot.on_demand ? (
          <div>
            <div>
              {appointment_slot.appointment_date}
            </div>
            <div>
              {appointment_slot.localized_starts_at_time}
            </div>
          </div>
        ) : (
          <div>On demand</div>
        )}
      </td>
      <td>
        <div className="fw-bold">
          <a href={user.links.participant_path}>
            {user.first_name} {user.last_name}
          </a>
        </div>
        <div className="flex">
          {user.date_of_birth} (age {user.age})
          {user.age < 18 && <span class="badge bg-light text-secondary ms-1">Minor</span>}
        </div>
      </td>
      <td>
        {user.phone_number && (
          <div>{user.phone_formatted}</div>
        )}
        {user.email && (
          <div>{user.email}</div>
        )}
      </td>
      <td>
        <div>
          {appointment_slot_group.title}
        </div>
      </td>
      <td>
        {!!archivedStatus && (
          <a className="text-decoration-none" href={ appointment.links.appointment }>
            <StatusBadge status={archivedStatus} className="me-1" />
          </a>
        )}
        {appointment['has_symptoms?'] && (
          <IconBadgeWithTooltip
            backgroundColor="#FFEFE8"
            color="#FB4E03"
            icon="fa-face-thermometer"
            title="Has symptoms"
          />
        )}
        {!appointment['consents_signed?'] && (
          <IconBadgeWithTooltip
            backgroundColor="#F3E2E3"
            color="#F70235"
            icon="fa-circle-exclamation"
            title="Missing consent"
          />
        )}
        {appointment.check_in_at && (
          <StatusBadge status={"checked_in"} className="me-1" />
        )}
      </td>
    </React.Fragment>
  );
};

export default ParticipantRow;
