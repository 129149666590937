import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import TestGroupUserRow from './TestGroupUserRow';
import { LocationContextProvider } from '../../LocationSelector/components/LocationContext';
import AssignAppointmentSlotGroupModal from './AssignAppointmentSlotGroupModal';

const TestGroupUsersTable = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [testGroupUserForModal, setTestGroupUserForModal] = useState({});
  const [customUrl, setCustomUrl] = useState('');
  const usersCount = props.filtered_test_group_users_count;

  const openModal = (href) => {
    setCustomUrl(href);
    setModalOpen(true);
  };

  const closeModal = () => {
    setCustomUrl('');
    setModalOpen(false);
  };
  return (
    <React.Fragment>
      <div className="fw-bold mt-4">{usersCount} results</div>
      {usersCount > 0 ? (
        <section className="fabrx-tables-light mt-4">
          <Table
            responsive
            data-test-hook="test_group_users_table"
            className="table-lg"
          >
            <thead className="text-gray-dark-700" style={{ backgroundColor: "#FBFBFB" }}>
              <tr>
                <th className="px-3 py-2">
                  <span>Patient</span>
                </th>
                <th className="px-3 py-2">
                  <span>Contact</span>
                </th>
                <th className="px-3 py-2">
                  <span>Status</span>
                </th>
                <th className="px-3 py-2"></th>
                <th className="px-3 py-2"></th>
              </tr>
            </thead>
            <tbody>
              {props.test_group_users.map((test_group_user) => (
                  <tr
                  key={test_group_user.id}
                  className="test_group_user__page"
                  data-test-hook={`test_group_user_${test_group_user.id}`}
                  >
                    <TestGroupUserRow
                      test_group_user={test_group_user}
                      key={test_group_user.id}
                      is_global_search={props.is_global_search}
                      on_demand_only={props.on_demand_only}
                      assignAppointmentSlotGroup={(href) => {
                        setTestGroupUserForModal(test_group_user);
                        openModal(href);
                      }}
                    />
                  </tr>
                ))}
            </tbody>
          </Table>
        </section>
      )
      : (
        <div className="text-center mt-4">
          No results found. Try changing your search criteria.
        </div>
      )}

      {modalOpen && (
        <AssignAppointmentSlotGroupModal
          show={modalOpen}
          onHide={closeModal}
          customUrl={customUrl}
          testGroupUser={testGroupUserForModal}
        />
      )}
    </React.Fragment>
  );
};

export default TestGroupUsersTable;
