import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faArrowRight, faXmark } from '@fortawesome/pro-solid-svg-icons';

const CustomInput = styled.input`
  width: ${({ width }) => width}px;
  margin: 0 4px;
  padding-top: 3px;
  border-width: 0 0 2px;
  border-color: transparent;

  &:focus {
    border-color: var(--vivid-blue);
  }
`;

const ClearButton = styled.button`
  padding: 12px;
  font-size: 0.9em;
  width: 18px;
  height: 18px;
  min-width: unset;
  min-height: unset;

  &:not(:hover) {
    background-color: unset !important;
    border-color: transparent !important;
  }

  & > * {
    fill: #cbd2e0 !important;
    color: #cbd2e0 !important;
  }

  &:hover {
    color: #999999;
  }
`;

const RangeInput = ({
  placeholder = 'Date range',
  rangeIsToday = false,
  sameDate = false,
  openCalendar,
  clearValues,
  startDayInputRef,
  endDayInputRef,
  format,
  stringDates,
  value,
}) => {
  const [from, to] = rangeIsToday
    ? ['Today', 'Today']
    : stringDates || value?.split(' ~ ');
  const unset = (!from && !to) || sameDate;
  const [focused, setFocused] = useState(false);

  const collapsed = (from === 'Today' && to === 'Today') || (unset && !focused);

  const startDayInputProps = collapsed
    ? {
        className: `w-100 px-2 ${from && to && sameDate ? 'text-center' : ''}`,
        placeholder,
        onFocus: () => {
          unset && setFocused(true);
          openCalendar();
        },
      }
    : {
        className: 'text-center',
        width: format.length * 16,
        placeholder: 'Start date',
        onFocus: openCalendar,
        onBlur: () => setTimeout(() => setFocused(false), 200),
      };

  return (
    <div
      className="form-control w-100 d-flex align-items-center justify-content-around position-relative px-1"
      style={{ minWidth: 212 }}
    >
      <CustomInput
        ref={startDayInputRef}
        value={from || ''}
        onChange={() => {}}
        readOnly
        {...startDayInputProps}
      />
      <div style={{ height: 14, width: 16 }}>
        <FontAwesomeIcon
          className={`text-secondary ${collapsed ? 'd-none' : ''}`}
          icon={faArrowRight}
        />
      </div>
      <CustomInput
        className={`text-center ${collapsed ? 'd-none' : ''}`}
        ref={endDayInputRef}
        width={format.length * 16}
        onFocus={openCalendar}
        value={to || ''}
        placeholder="End date"
        onChange={() => {}}
        readOnly
      />
      {(from || to) && (
        <ClearButton
          className="btn btn-light"
          type="button"
          onClick={clearValues}
        >
          <FontAwesomeIcon icon={faXmark} />
        </ClearButton>
      )}
    </div>
  );
};

const valuesFromProps = (valuesProp) => {
  const hasValues = valuesProp.filter((x) => x).length > 0;

  return hasValues
    ? valuesProp.map((v) => v && new DateObject(v)).filter((x) => x)
    : [];
};

const DateRangeField = ({
  values: valuesProp = [],
  start_day_field_name,
  startDayFieldName = start_day_field_name,
  end_day_field_name,
  endDayFieldName = end_day_field_name,
  placeholder = 'Date range',
  date_format = 'YYYY/MM/DD',
  dateFormat = date_format,
  onChange = () => {},
  includeToday = true,
  clearDate,
}) => {
  const [rangeIsToday, setRangeIsToday] = useState(
    valuesProp[0] == valuesProp[1] && valuesProp[0] == 'today',
  );
  const [values, _setValues] = useState(valuesFromProps(valuesProp));
  const [startDay, endDay] = values;

  const datePickerRef = useRef();
  const startDayInputRef = useRef(null);
  const endDayInputRef = useRef(null);

  const setValues = (values) => {
    setRangeIsToday(false);
    _setValues(values);
  };

  useEffect(() => {
    if (values.length === 1) endDayInputRef.current.focus();
    if (values.length === 2) {
      datePickerRef.current.closeCalendar();
    }
  }, [values]);

  const clearValues = () => {
    setValues([]);
    clearDate && clearDate();
  };

  useEffect(() => {
    _setValues(valuesFromProps(valuesProp));
  }, [valuesProp]);

  return (
    <React.Fragment>
      {(rangeIsToday || startDay) && (
        <input
          id={startDayFieldName}
          name={startDayFieldName}
          value={rangeIsToday ? 'today' : startDay.format('YYYY-MM-DD')}
          type="hidden"
        />
      )}
      {(rangeIsToday || endDay) && (
        <input
          id={endDayFieldName}
          name={endDayFieldName}
          value={rangeIsToday ? 'today' : endDay.format('YYYY-MM-DD')}
          type="hidden"
        />
      )}
      <DatePicker
        ref={datePickerRef}
        calendarPosition="bottom-center"
        fixRelativePosition
        value={values}
        onChange={(values) => {
          setValues(values);
          if (values.length === 2) {
            onChange && onChange(values[0], values[1]);
          }
        }}
        range
        containerClassName="w-100"
        placeholder={placeholder}
        format={dateFormat}
        type="custom"
        render={
          <RangeInput
            placeholder={placeholder}
            rangeIsToday={rangeIsToday}
            sameDate={
              values[0]?.valueOf() === values[1]?.valueOf() || rangeIsToday
            }
            clearValues={clearValues}
            startDayInputRef={startDayInputRef}
            endDayInputRef={endDayInputRef}
            format={dateFormat}
          />
        }
      >
        {includeToday && (
          <button
            className="btn btn-primary btn-sm mb-2"
            disabled={rangeIsToday}
            onClick={(e) => {
              e.preventDefault();
              setRangeIsToday(true);
              onChange('today');
            }}
          >
            Today
          </button>
        )}
      </DatePicker>
    </React.Fragment>
  );
};

export default DateRangeField;
