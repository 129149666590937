import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { today } from '../../../common/utils';

const CheckoutButtons = ({
  appointment,
  links,
  onCheckIn,
}) => {
  const newTables = appointment.test_group.flipper_flags.new_tables;
  const oldTablesClass = appointment.check_in_at
    ? 'primary'
    : 'primary not-allowed';
  const checkedInClass = newTables ? "outline-gray" : oldTablesClass;
  const checkInVariant = newTables
    ? "outline-gray"
    : appointment.check_in_at ? 'outline-primary' : 'primary'
  const checkOutVariant = newTables
    ? "outline-gray"
    : appointment['checked_out?'] ? 'outline-primary' : `${checkedInClass}`

  const enforceStrictCheckIn =
    appointment.test_group.require_strict_check_in &&
    !today(appointment.appointment_slot.localized_day) &&
    !appointment.on_demand;

  const notOnDemandCheckout = !appointment.on_demand;

  return notOnDemandCheckout && (appointment.archived_status || enforceStrictCheckIn) ? (
    <td colSpan="2">
      {appointment.archived_status ? (
        <Button
          variant={newTables ? "outline-gray" : "outline-primary"}
          href={links.create_appointment}
        >
          Schedule another appointment
        </Button>
      ) : (
        <Button
          variant={newTables ? "outline-gray" : "outline-primary"}
          href={links.appointment + '?tab=edit'}
        >
          Reschedule appointment
        </Button>
      )}
    </td>
  ) : (
    <React.Fragment>
      <td className="text-center">
        <Button
          onClick={() => onCheckIn(appointment)}
          variant={checkInVariant}
          data-test-hook="check_in"
        >
          {appointment.check_in_at ? 'Checked in' : 'Check in'}
        </Button>
      </td>
      <td>
        <a
          className={`btn btn-${checkOutVariant}`}
          href={links.checkout_link}
          data-test-hook="check_out"
        >
          {appointment['checked_out?'] ? 'Checked out' : 'Check out'}
        </a>
      </td>
    </React.Fragment>
  );
};

export default CheckoutButtons;
